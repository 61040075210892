export class PrintByLabel {
  constructor(
    Vendor = '',
    Connection = '',
    IterateCount = 0,
    LabelId = 0,
    Params = null,
  ) {
    this.Vendor = Vendor
    this.Connection = Connection
    this.IterateCount = IterateCount
    this.LabelId = LabelId
    this.Params = Params
  }
}
