<template>
  <b-row class="match-height">
    <dx-util-popup
      :visible="visiblePrintOutPopup"
      :hide-on-outside-click="true"
      :show-title="true"
      :drag-enabled="true"
      :width="720"
      :height="290"
      position="center"
      title="Print Out Console"
      @hidden="onHidden"
    >
      <div style="display: block" class="printermanagement">
        <div class="mt-10px">
          <dx-util-select-box
            :items="labels"
            :value="labels[0]"
            :display-expr="labelName"
            @value-changed="onselectedlabels"
          />
        </div>
        <div class="mt-10px">
          <label for="demo-sb">Iterate Count</label>
            <b-form-spinbutton
              id="demo-sb"
              v-model="printOutIterateCount"
              min="1"
              max="100"
            />
        </div>
        <div class="mt-10px">
          <b-button
            variant="outline-primary"
            @click="printOut()"
            >
            Print Out
          </b-button>
        </div>
      </div>
    </dx-util-popup>
    <!-- #region Installer -->
    <div
    v-if="downloadInstaller"
    class="downloadinstaller"
    >
      <b-col
        cols="12"
      >
        <b-card title="Could not find PSH Printer application or its not working in your computer">
          <b-card-text>
            To be able to use print out feature of PSH, you have to download/install or run PSH Printer application.

            <br>
            <br>
            <b>Supported Devices</b>
            <ul>
              <li>Windows (32)</li>
              <li>Windows (64)</li>
            </ul>
            <b>Supported Printers</b>
            <ul>
              <li>Zebra</li>
              <li>Dymo</li>
            </ul>
          </b-card-text>
          <a
            href="https://pshfiles.s3.eu-central-1.amazonaws.com/PrepShipHub.PrintingApp.Installer.zip"
            target="_blank"
            >
            Download
          </a>
          -
          <a
            href="javascript:;"
            @click="loadAvailablePrinters()"
            >
            Resync
          </a>
        </b-card>
      </b-col>
    </div>
    <!-- #endregion -->
    <!-- #region PrinterList -->
    <div
      v-if="downloadInstaller === false"
      class="printerlist"
      style="display: contents"
      >
      <b-col
        v-for="printer, index in printers"
        :key="index"
        md="6"
        lg="4"
      >
        <b-card :title="printer.VendorName">
          <b-card-text>
            {{ printer.Model }}
          </b-card-text>
          <b-button
            variant="outline-primary"
            @click="loadlabels(printer, printer.VendorName)"
            >
            Print Out
          </b-button>
        </b-card>
      </b-col>
    </div>
    <!-- #endregion -->
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BFormSpinbutton,
} from 'bootstrap-vue'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { Notify } from '@robustshell/utils/index'
import printeragentapi from '@/http/requests/printer/printer-agent'
// import Swal from 'sweetalert2'
import { PrintByLabel } from '@/http/models/printer/entity/printbylabel'
import { LabelParam } from '@/http/models/printer/entity/labelparam'

export default {
  name: 'PrintOutTest',
  components: {
    BFormSpinbutton,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
  },
  mixins: [GridBase],
  data() {
    return {
      downloadInstaller: false,
      visibleLabelsSelection: false,
      selectedPrinter: null,
      printers: [],
      selectedLabelType: null,
      labelTypes: [],
      selectedLabel: null,
      labels: [],
      visiblePrintOutPopup: false,
      printByLabel: new PrintByLabel(),
      labelParam: new LabelParam(),
      labelParams: [],
      printOutIterateCount: 1,
    }
  },
  mounted() {
    this.loadAvailablePrinters()
  },
  methods: {
    labelName(e) {
      if (e !== null) {
        return `${e.Name} (${e.Width} * ${e.Height})`
      }
      return ''
    },
    onselectedlabels(e) {
      this.selectedLabel = e.value
    },
    onHidden() {
      this.visiblePrintOutPopup = false
    },
    loadAvailablePrinters() {
      printeragentapi.isAgentInstalled().then(() => {
        printeragentapi.availablePrinters().then(result => {
          this.downloadInstaller = false
          this.printers = result.data
          const printerSystemVersion = result.data[0].SystemVersion
          this.checkVersion(printerSystemVersion)
        })
      }).catch(error => {
        const errorMessage = String(error)
        if (errorMessage === 'Error: Network Error') {
          this.downloadInstaller = true
          Notify.error('Could not find PSH Printer application')
        }
      })
    },
    loadlabels(printer, vendorname) {
      this.selectedPrinter = printer
      this.visiblePrintOutPopup = true
      printeragentapi.labelsByVendor(vendorname).then(result => {
        this.labels = result.data
        if (this.labels.length > 0) {
          this.visibleLabelsSelection = true
        } else {
          Notify.error('Could not find any label')
        }
      })
    },
    printOut() {
      if (this.selectedLabel === null) {
        Notify.warning('Please select a label')
        return
      }

      // #region Param Set
      this.labelParam.Merge = '%%BARCODE%%'
      this.labelParam.Value = '1591529159163'
      // #endregion
      // DYMO -> 10 - Id? 2DContent

      this.labelParams.push(this.labelParam)
      this.labelParams.push(new LabelParam('%%BARCODE1%%', '1591529159163'))

      this.printByLabel.Vendor = this.selectedPrinter.VendorName
      this.printByLabel.Connection = this.selectedPrinter.Address
      this.printByLabel.IterateCount = this.printOutIterateCount
      this.printByLabel.LabelId = this.selectedLabel.Id
      this.printByLabel.Params = this.labelParams

      printeragentapi.printoutbylabel(this.printByLabel).then(result => {
        const response = result.data
        if (response === true) {
          this.printByLabel = new PrintByLabel()
          this.labelParam = new LabelParam()
          this.labelParams = []
          Notify.success('Print out job sent')
        } else {
          Notify.error('Job failed')
        }
      })
    },
    checkVersion(printerSystemVersion) {
      if (printerSystemVersion !== null
      && printerSystemVersion !== undefined) {
        const PrinterVersion = localStorage.getItem('PrinterVersion')
        if (PrinterVersion !== null
        && PrinterVersion !== undefined
        && PrinterVersion > printerSystemVersion) {
          // const swalMessage = Swal.mixin({
          //   customClass: {
          //     confirmButton: 'btn btn-block btn-primary',
          //   },
          //   buttonsStyling: false,
          // })
          // swalMessage.fire({
          //   title: 'New Version',
          //   text: 'There is a new version for PSH Printer Application',
          //   type: 'info',
          //   icon: 'info',
          //   allowOutsideClick: false,
          //   showCancelButton: true,
          //   confirmButtonText: 'Download!',
          //   cancelButtonText: 'Close',
          //   customClass: {
          //     confirmButton: 'btn btn-primary',
          //     cancelButton: 'btn btn-outline-warning ml-1',
          //   },
          // }).then(result => {
          //   if (result.isConfirmed) {
          //     window.open('https://pshfiles.s3.eu-central-1.amazonaws.com/zsu-1191295.zip')
          //   }
          // })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/printermanagement/printermanagement.scss';
</style>
